import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import Cookies from "js-cookie";

import "../styles/spinner.scss";
import "../styles/stripeRegister.scss";

function StripeRegisterForm({
  submitCampaignID,
  submitType,
  standardPriceId,
  academicPriceId,
  form,
}) {
  const tandc = (
    <p>
      By registering for this program/event, you are accepting Meridian
      University's <a href="/terms-and-conditions">Terms and Conditions.</a>
    </p>
  );
  const stripe = useStripe();
  const elements = useElements();

  /* Get GAUID */
  const clientId = typeof document !== "undefined" ? Cookies.get("_ga") : null;
  const gaUID =
    clientId != null || clientId != undefined
      ? clientId.substring(6)
      : "No GAUID Detected";

  /* Get Referral Value */
  let referrer = typeof document !== "undefined" ? document.referrer.toLowerCase() : null;
  referrer = (!referrer) || (referrer === null) ? "Direct" : referrer;
  let muReferrer = Cookies.get("_muReferrer");
  let referrerCookie;
  referrerCookie =
    muReferrer === undefined
      ? (Cookies.set("_muReferrer", referrer, { expires: 7 }),
        (referrerCookie = Cookies.get("_muReferrer")))
      : muReferrer === "Direct" && referrer !== "Direct"
        ? (Cookies.set("_muReferrer", referrer, { expires: 7 }),
          (referrerCookie = Cookies.get("_muReferrer")))
        : muReferrer !== "Direct" && referrer !== "Direct"
          ? (Cookies.set("_muReferrer", referrer, { expires: 7 }),
            (referrerCookie = Cookies.get("_muReferrer")))
          : (referrerCookie = muReferrer);
         
  /* Get URL Params */
  const urlRef = typeof window !== "undefined" ? window.location.search : "";
  const url = urlRef.slice(1);
  const urlParams = new URLSearchParams(url);
  const utm_source = urlParams.has("utm_source")
    ? urlParams.get("utm_source")
    : "NoSource";
  const utm_medium = urlParams.has("utm_medium")
    ? urlParams.get("utm_medium")
    : "NoMedium";
  const utm_campaign = urlParams.has("utm_campaign")
    ? urlParams.get("utm_campaign")
    : "NoCampaign";
  const utm_term = urlParams.has("utm_term")
    ? urlParams.get("utm_term")
    : "NoTerm";
  const utm_content = urlParams.has("utm_content")
    ? urlParams.get("utm_content")
    : "NoContent";
  const utm_gclid = urlParams.has("gclid") ? urlParams.get("gclid") : "NoGCLID";

  /* Get Initial Cookie Value */
  let muSource = Cookies.get("_muSource");
  let muMedium = Cookies.get("_muMedium");
  let muCampaign = Cookies.get("_muCampaign");
  let muTerm = Cookies.get("_muTerm");
  let muContent = Cookies.get("_muContent");

  /* Set Cookie Value and Store for Logic App Values */
  let source;
  let medium;
  let campaign;
  let term;
  let content;
  source =
    muSource === undefined
      ? (Cookies.set("_muSource", utm_source, { expires: 7 }),
        (source = Cookies.get("_muSource")))
      : muSource === "NoSource" && utm_source !== "NoSource"
        ? (Cookies.set("_muSource", utm_source, { expires: 7 }),
          (source = Cookies.get("_muSource")))
        : muSource !== "NoSource" && utm_source !== "NoSource"
          ? (Cookies.set("_muSource", utm_source, { expires: 7 }),
            (source = Cookies.get("_muSource")))
          : (source = muSource);
  medium =
    muMedium === undefined
      ? (Cookies.set("_muMedium", utm_medium, { expires: 7 }),
        (medium = Cookies.get("_muMedium")))
      : muMedium === "NoMedium" && utm_medium !== "NoMedium"
        ? (Cookies.set("_muMedium", utm_medium, { expires: 7 }),
          (medium = Cookies.get("_muMedium")))
        : muMedium !== "NoMedium" && utm_medium !== "NoMedium"
          ? (Cookies.set("_muMedium", utm_medium, { expires: 7 }),
            (medium = Cookies.get("_muMedium")))
          : (medium = muMedium);
  campaign =
    muCampaign === undefined
      ? (Cookies.set("_muCampaign", utm_campaign, { expires: 7 }),
        (campaign = Cookies.get("_muCampaign")))
      : muCampaign === "NoCampaign" && utm_campaign !== "NoCampaign"
        ? (Cookies.set("_muCampaign", utm_campaign, { expires: 7 }),
          (campaign = Cookies.get("_muCampaign")))
        : muCampaign !== "NoCampaign" && utm_campaign !== "NoCampaign"
          ? (Cookies.set("_muCampaign", utm_campaign, { expires: 7 }),
            (campaign = Cookies.get("_muCampaign")))
          : (campaign = muCampaign);
  term =
    muTerm === undefined
      ? (Cookies.set("_muTerm", utm_term, { expires: 7 }),
        (term = Cookies.get("_muTerm")))
      : muTerm === "NoTerm" && utm_term !== "NoTerm"
        ? (Cookies.set("_muTerm", utm_term, { expires: 7 }),
          (term = Cookies.get("_muTerm")))
        : muTerm !== "NoTerm" && utm_term !== "NoTerm"
          ? (Cookies.set("_muTerm", utm_term, { expires: 7 }),
            (term = Cookies.get("_muTerm")))
          : (term = muTerm);
  content =
    muContent === undefined
      ? (Cookies.set("_muContent", utm_content, { expires: 7 }),
        (content = Cookies.get("_muContent")))
      : muContent === "NoContent" && utm_content !== "NoContent"
        ? (Cookies.set("_muContent", utm_content, { expires: 7 }),
          (content = Cookies.get("_muContent")))
        : muContent !== "NoContent" && utm_content !== "NoContent"
          ? (Cookies.set("_muContent", utm_content, { expires: 7 }),
            (content = Cookies.get("_muContent")))
          : (content = muContent);

  const logicAppUrl =
    "https://prod-09.westcentralus.logic.azure.com:443/workflows/8c39d89aef1d4ebab7a131cc286d7d99/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=BtHVmT4_d9IiEqMd50m2sO7N0mwxWz7pW2Q_t5hH_wQ";

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      submitFirstName: "",
      submitLastName: "",
      submitEmail: "",
      tcCheckBox: false,
      submitCampaignID,
      submitType,
      submitUTMSource: source,
      submitUTMMedium: medium,
      submitUTMCampaign: campaign,
      submitUTMTerm: term,
      submitUTMContent: content,
      submitReferrer: referrerCookie,
      submitGCLID: utm_gclid,
      submitUUID: gaUID,
      submitForm: "Stripe Register Form",
    },

    validationSchema: Yup.object({
      submitFirstName: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Required"),
      submitLastName: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("Required"),
      submitEmail: Yup.string()
        .email("Invalid email address")
        .required("Required"),
      submitStripeRegister: Yup.string()
        .required("Please Select A Registration Type")
        .oneOf(["standard", "standard-with-academic"]),
      submitGCLID: Yup.string(),
      tcCheckBox: Yup.boolean().oneOf(
        [true],
        "Please Accept the Terms and Conditions to Proceed.",
      ),
      submitUUID: Yup.string(),
    }),

    onSubmit: (values) => {
      if (!values.submitStripeRegister) {
        return false;
      }

      let priceID =
        values.submitStripeRegister === "standard"
          ? standardPriceId
          : academicPriceId;

      fetch(logicAppUrl, {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        method: "post",
        body: JSON.stringify(values),
      }).then(
        stripe.redirectToCheckout({
          lineItems: [
            {
              price: priceID,
              quantity: 1,
            },
          ],
          mode: "payment",
          successUrl: `https://meridianuniversity.edu/thankyou?form=${form}`,
          cancelUrl: "https://meridianuniversity.edu/cancel-order/",
          customerEmail: values.submitEmail,
        }),
      );
    },
  });

  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive);
  };

  return (
    <div>
      <div id="submitting" className={isActive ? null : "show"}>
        <Row className="justify-content-center align-items-center">
          <Col xs={12} className="p-3 p-lg-4 text-center">
            <h2 className="submitting-title">
              Submitting{" "}
              <FontAwesomeIcon icon={faSpinner} className="fa-spin" size="2x" />
            </h2>
          </Col>
        </Row>
      </div>
      <Form onSubmit={formik.handleSubmit} id="form">
        <Form.Group controlId="namesGroup" className="names">
          <Form.Group className="first">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              id="submitFirstName"
              name="submitFirstName"
              type="text"
              placeholder="First Name"
              onChange={formik.handleChange}
              value={formik.values.submitFirstName}
              className={
                formik.touched.submitFirstName && formik.errors.submitFirstName
                  ? `error`
                  : null
              }
            />
            {formik.touched.submitFirstName && formik.errors.submitFirstName ? (
              <div className="error-text">{formik.errors.submitFirstName}</div>
            ) : null}
          </Form.Group>
          <Form.Group className="last">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              id="submitLastName"
              name="submitLastName"
              type="text"
              placeholder="Last Name"
              onChange={formik.handleChange}
              value={formik.values.submitLastName}
              className={
                formik.touched.submitLastName && formik.errors.submitLastName
                  ? `error`
                  : null
              }
            />
            {formik.touched.submitLastName && formik.errors.submitLastName ? (
              <div className="error-text">{formik.errors.submitLastName}</div>
            ) : null}
          </Form.Group>
        </Form.Group>
        <Form.Group className="email">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            id="submitEmail"
            name="submitEmail"
            type="email"
            placeholder="Email Address"
            onChange={formik.handleChange}
            value={formik.values.submitEmail}
            className={
              formik.touched.submitEmail && formik.errors.submitEmail
                ? `error`
                : null
            }
          />
          {formik.touched.submitEmail && formik.errors.submitEmail ? (
            <div className="error-text">{formik.errors.submitEmail}</div>
          ) : null}
        </Form.Group>
        <Form.Group className="register-type">
          <Form.Select
            required
            aria-label="Register Type"
            id="submitStripeRegister"
            defaultValue=""
            name="submitStripeRegister"
            value={formik.values.submitStripeRegister}
            onChange={formik.handleChange}
            className={
              formik.touched.submitStripeRegister &&
              formik.errors.submitStripeRegister
                ? `error`
                : null
            }
          >
            <option disabled={true} value="">
              REGISTRATION TYPE
            </option>
            <option value="standard">Standard Registration - $250.00</option>
            <option value="standard-with-academic">
              Standard with Academic Credit - $1,300
            </option>
          </Form.Select>
          {formik.touched.submitStripeRegister &&
          formik.errors.submitStripeRegister ? (
            <div className="error-text">
              {formik.errors.submitStripeRegister}
            </div>
          ) : null}
        </Form.Group>
        <Form.Group className="tc">
          <Form.Check
            inline
            id="tcCheckBox"
            name="tcCheckBox"
            label={tandc}
            type="checkbox"
            onChange={formik.handleChange}
            className={
              formik.touched.tcCheckBox && formik.errors.tcCheckBox
                ? `error`
                : null
            }
          />
          {formik.touched.tcCheckBox && formik.errors.tcCheckBox ? (
            <div className="error-text">{formik.errors.tcCheckBox}</div>
          ) : null}
        </Form.Group>
        <Form.Group>
          <Form.Control
            id="submitGCLID"
            name="submitGCLID"
            type="hidden"
            value=""
          />
        </Form.Group>
        <Button
          variant="primary"
          type="submit"
          onClick={ToggleClass}
          disabled={!(formik.isValid && formik.dirty)}
        >
          Checkout
        </Button>
      </Form>
    </div>
  );
}

export default StripeRegisterForm;
